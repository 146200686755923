import {
  LOCAL_STORAGE_KEY_INIT_ROUTE,
  PORTAL_AREAS,
  PUBLIC_ROUTES,
  SIGN_OUT_ROUTE
} from '@/constants'
import { loadAssets } from '@/load-assets'
import { setupApp } from '@/setup-app'
import { signOut } from '@/sign-out'
import { verifyAuth } from '@/verify-auth'

/**
 * Main function to handle the route and set up app
 * @param {string} route
 */
export const handleRouting = async (route) => {
  // check to see if user is logged in
  const userIsVerified = await verifyAuth()
  if (userIsVerified) {
    // sign out route
    if (route.startsWith(SIGN_OUT_ROUTE)) {
      signOut()
    } else {
      setupApp()
        .then((practiceInfo) => { // if already logged in, then load relevant app
          let app
          const queryParams = new URLSearchParams(route.split('?')[1])
          const initPath = queryParams.get('path')

          // handle routing from monolith
          if (route.includes('?rt=')) {
            route = decodeURIComponent(route.split('?rt=')[1])
            window.history.replaceState(route, 'Tebra', route)
          }

          // handle routing from query param
          if (initPath) {
            route = initPath.slice(1)
            history.replaceState(initPath, 'Tebra', initPath)
          }

          // (route.startsWith('sso/redirect') && !initPath)
          // If no route provided or its one of the public routes handle default
          if (!route || route.startsWith('#') || (route.startsWith('sso/redirect') && !initPath) || PUBLIC_ROUTES.filter((v) => route.startsWith(v)).length > 0) {
            app = practiceInfo.launchdate && (new Date() > new Date(practiceInfo.launchdate)) ? 'DASHBOARD' : 'ONBOARDING'
            if (app === 'DASHBOARD') {
              window.history.replaceState(null, 'Tebra', '/dashboard/summary')
            }
          } else {
            Object.keys(PORTAL_AREAS).every((protectedRoute) => {
              if (route.startsWith(PORTAL_AREAS[protectedRoute].PATH)) {
                app = protectedRoute
                return false
              }
              return true
            })

            // default to global pages
            if (!app) {
              app = 'GLOBAL'
            }
          }
          loadAssets(app)
        })
    }
  } else {
    if (route.includes('?rt=')) { // if it contains old routing from monolith
      const initRoute = decodeURIComponent(route.split('?rt=')[1])
      window.localStorage.setItem(LOCAL_STORAGE_KEY_INIT_ROUTE, initRoute)
    } else if (route.includes('token')) { // for SSO use query param path
      const queryParams = new URLSearchParams(route)
      const initPath = queryParams.get('path')
      if (initPath) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_INIT_ROUTE, initPath)
      }
    } else if ( // otherwise set init route and load sign in page (only if it's not one of the public routes)
      route &&
      !route.includes('token') &&
      !route.includes('reset-password') &&
      !route.includes('forgotpassword/reset') &&
      PUBLIC_ROUTES.filter((v) => route.startsWith(v)).length === 0 &&
      !route.startsWith(SIGN_OUT_ROUTE)
    ) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY_INIT_ROUTE, route)
    }

    loadAssets('LOGIN')
  }
}
