import { handleNetworkError } from '@/handle-network-error'
import { handleRouting } from '@/handle-routing'
import { portalSettings } from '@/portal-settings'
import '@/styles.scss'

// handle any 401 or network errors
handleNetworkError()

// handle loading relevant app - see ARCHITECTURE.md for more info
handleRouting((window.location.pathname + window.location.search + window.location.hash).substring(1))

// set up portal settings
portalSettings()
