export const COOKIE_NAME = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  PRACTICE_ID: 'practice_id',
  USER_TYPE: 'user_type',
  LOGIN_DOMAIN: 'login_domain'
}

export const LOCAL_STORAGE_KEY_API_RESPONSE_TIMEOUT_CONFIG = 'ppApiResponseTimeoutConfig'
export const LOCAL_STORAGE_KEY_API_RESPONSE_PRACTICE_INFO = 'ppApiResponsePracticeInfo'
export const LOCAL_STORAGE_KEY_INIT_ROUTE = 'ppInitRoute'
export const LOCAL_STORAGE_KEY_LOGGED_OUT = 'ppLoggedOut'
export const LOCAL_STORAGE_IS_ACTIVE = 'ppIsActive'

export const SIGN_OUT_ROUTE = 'logout'

export const PUBLIC_ROUTES = [
  'sign-in',
  'forgot-password',
  'reset-password'
]

export const LOGIN_APP = {
  ASSET_PATH: process.env.PORTAL_APP_LOGIN,
  DEPENDENT_ON_CORE_UI: false
}

export const GLOBAL_PAGES_APP = {
  ASSET_PATH: process.env.PORTAL_APP_GLOBAL,
  DEPENDENT_ON_CORE_UI: true
}

export const PORTAL_AREAS = {
  INSIGHTS: {
    PATH: 'insights',
    ASSET_PATH: process.env.PORTAL_APP_INSIGHTS,
    DEPENDENT_ON_CORE_UI: false
  },
  PATIENTS: {
    PATH: 'patients',
    ASSET_PATH: process.env.PORTAL_APP_PATIENTS,
    DEPENDENT_ON_CORE_UI: false
  },
  INBOX: {
    PATH: 'inbox',
    ASSET_PATH: process.env.PORTAL_APP_INBOX,
    DEPENDENT_ON_CORE_UI: false
  },
  SCHEDULING: {
    PATH: 'scheduling',
    ASSET_PATH: process.env.PORTAL_APP_SCHEDULING,
    DEPENDENT_ON_CORE_UI: true
  },
  CALENDAR: {
    PATH: 'calendar',
    ASSET_PATH: process.env.PORTAL_APP_CALENDAR,
    DEPENDENT_ON_CORE_UI: false
  },
  TELEHEALTH: {
    PATH: 'telehealth',
    ASSET_PATH: process.env.PORTAL_APP_TELEHEALTH,
    DEPENDENT_ON_CORE_UI: true
  },
  PHONE_LEADS: {
    PATH: 'call-tracking',
    ASSET_PATH: process.env.PORTAL_APP_PHONE_LEADS,
    DEPENDENT_ON_CORE_UI: true
  },
  PAYMENTS: {
    PATH: 'payments',
    ASSET_PATH: process.env.PORTAL_APP_PAYMENTS,
    DEPENDENT_ON_CORE_UI: false
  },
  REPUTATION: {
    PATH: 'reputation',
    ASSET_PATH: process.env.PORTAL_APP_REPUTATION,
    DEPENDENT_ON_CORE_UI: true
  },
  QUESTIONS: {
    PATH: 'questions',
    ASSET_PATH: process.env.PORTAL_APP_QUESTIONS,
    DEPENDENT_ON_CORE_UI: false
  },
  BLOG: {
    PATH: 'blog',
    ASSET_PATH: process.env.PORTAL_APP_BLOG,
    DEPENDENT_ON_CORE_UI: true
  },
  RETAIN: {
    PATH: 'retain',
    ASSET_PATH: process.env.PORTAL_APP_RETAIN,
    DEPENDENT_ON_CORE_UI: true
  },
  RECALL: {
    PATH: 'recall',
    ASSET_PATH: process.env.PORTAL_APP_RECALL,
    DEPENDENT_ON_CORE_UI: false
  },
  WEB_PRESENCES: {
    PATH: 'web-presence',
    ASSET_PATH: process.env.PORTAL_APP_WEB_PRESENCES,
    DEPENDENT_ON_CORE_UI: true
  },
  WEBSITE: {
    PATH: 'website',
    ASSET_PATH: process.env.PORTAL_APP_WEBSITE,
    DEPENDENT_ON_CORE_UI: false
  },
  WEB_EDITOR: {
    PATH: 'web-editor',
    ASSET_PATH: process.env.PORTAL_APP_WEBSITE,
    DEPENDENT_ON_CORE_UI: false
  },
  ONBOARDING: {
    PATH: 'onboarding',
    ASSET_PATH: process.env.PORTAL_APP_ONBOARDING,
    DEPENDENT_ON_CORE_UI: true
  },
  DASHBOARD: {
    PATH: 'dashboard',
    ASSET_PATH: process.env.PORTAL_APP_DASHBOARD,
    DEPENDENT_ON_CORE_UI: true
  },
  ADVERTISING: {
    PATH: 'advertising',
    ASSET_PATH: process.env.PORTAL_APP_DASHBOARD,
    DEPENDENT_ON_CORE_UI: true
  },
  SEO: {
    PATH: 'seo',
    ASSET_PATH: process.env.PORTAL_APP_DASHBOARD,
    DEPENDENT_ON_CORE_UI: true
  },
  INTEGRATIONS: {
    PATH: 'integrations',
    ASSET_PATH: process.env.PORTAL_APP_INTEGRATIONS,
    DEPENDENT_ON_CORE_UI: true
  },
  CARE_CONNECT_PROFILES: {
    PATH: 'care-connect-profiles',
    ASSET_PATH: process.env.PORTAL_APP_CARE_CONNECT_PROFILES,
    DEPENDENT_ON_CORE_UI: false
  }
}

export const TINY_MCE_JS = 'https://ui-cdn.patientpop.com/static/js/tinymce_v4.7.13/tinymce.min.js'

export const USER_EVENTS = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart', 'click']
