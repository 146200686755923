import { LOGIN_APP, GLOBAL_PAGES_APP, PORTAL_AREAS, TINY_MCE_JS } from '@/constants'

/**
 * Append script to head
 * @param {string} scriptSrc
 * @param {string} nextScript
 */
const appendScript = (scriptSrc, nextScript = '') => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = scriptSrc

  // if there is next script, load that when the initial one finishes
  if (nextScript) {
    script.onload = () => appendScript(nextScript)
  }

  document.head.appendChild(script)
}

/**
 * Append stylesheet to head
 * @param {string} cssSrc
 */
const appendCss = (cssSrc) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = cssSrc

  // add to the top of <head>
  document.head.insertBefore(link, document.head.firstChild)
}

/**
 * Load assets specific to the relevant portal app
 * @param {string} app
 */
export const loadAssets = (app) => {
  const appConfig = app === 'LOGIN' ? LOGIN_APP : app === 'GLOBAL' ? GLOBAL_PAGES_APP : PORTAL_AREAS[app]

  // if it's a legacy portal, inject core-ui JS followed by app JS and add core-ui CSS
  if (appConfig.DEPENDENT_ON_CORE_UI) {
    appendScript(`${process.env.PORTAL_APP_CORE}/js/core.js`, `${appConfig.ASSET_PATH}/js/app.js`)
    appendCss(`${process.env.PORTAL_APP_CORE}/css/core.css`)

    // add tinymce if its blog
    if (app === 'BLOG') {
      appendScript(TINY_MCE_JS)
    }
  } else {
    appendScript(`${appConfig.ASSET_PATH}/js/app.js`)
    appendScript(`${appConfig.ASSET_PATH}/js/chunk-vendors.js`)
  }

  // load the styles for this app
  appendCss(`${appConfig.ASSET_PATH}/css/app.css`)

  window.ppCdnvPath = appConfig.ASSET_PATH
}
