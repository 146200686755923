import { trackEventInGa } from 'pp-fe-utils'

/**
 * Decode JWT Token
 * @param {string} token
 * @returns {}
 */
export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  } catch (e) {
    // track error
    trackEventInGa('error-parsing-jwt-token', { error: e.message })

    // in error redirect to sign in
    window.location.href = '/sign-in'
  }
}
