import { getCookie } from '@/get-cookie'
import { parseJwt } from '@/parse-jwt'
import { getRefreshToken } from '@/get-refresh-token'
import {
  COOKIE_NAME,
  LOCAL_STORAGE_IS_ACTIVE,
  LOCAL_STORAGE_KEY_API_RESPONSE_PRACTICE_INFO,
  LOCAL_STORAGE_KEY_API_RESPONSE_TIMEOUT_CONFIG,
  SIGN_OUT_ROUTE,
  USER_EVENTS
} from '@/constants'
import { trackEventInGa } from 'pp-fe-utils'

/**
 * Call Practice end point to get info - used to determine legacy practice ID
 * @param {string} practiceUuid
 * @param {string} apiToken
 * @returns {Promise<any>}
 */
const getPracticeInfo = async (practiceUuid, apiToken) => {
  return fetch(`${process.env.APIS_DATA_DOMAIN}/v1/practices/${practiceUuid}?include=join_date,practice_logo_url`, {
    headers: {
      'pp-api-auth-token': apiToken,
      'pp-practice-uuid': practiceUuid,
      'pp-auth-strategy': 'user',
      'pp-service-name': 'pp-portal'
    }
  })
    .then((response) => response.json())
    .catch((e) => {
      return Promise.reject(e)
    })
}

/**
 * Get the timeout configuration for the user to determine when to force a log out
 * @param {string} practiceUuid
 * @param {string} apiToken
 * @returns {Promise<any>}
 */
const getTimeOutConfig = async (practiceUuid, apiToken) => {
  return fetch(`${process.env.APIS_DATA_DOMAIN}/practice-portal/v1/timeout-configuration`, {
    headers: {
      'pp-api-auth-token': apiToken,
      'pp-practice-uuid': practiceUuid,
      'pp-auth-strategy': 'user',
      'pp-service-name': 'pp-portal'
    }
  })
    .then((response) => response.json())
    .catch((e) => {
      return Promise.reject(e)
    })
}

/**
 * Parse and find out exp time, and then set a timeout to get new token
 * @param {string} accessToken
 */
const refreshAccessToken = (accessToken) => {
  const decodedJwt = parseJwt(accessToken)
  const timeToRefresh = (decodedJwt.exp * 1000 - 60000) - Date.now()
  setTimeout(getRefreshToken, timeToRefresh, refreshAccessToken)
}

/**
 * Setup application and store relevant params and set timeout to get refresh token when current token expires
 * @returns {Promise<*>}
 */
export const setupApp = async () => {
  const accessToken = getCookie(COOKIE_NAME.ACCESS_TOKEN)
  const refreshToken = getCookie(COOKIE_NAME.REFRESH_TOKEN)
  const practiceId = getCookie(COOKIE_NAME.PRACTICE_ID)
  let practiceInfo, timeoutConfiguration

  // get practice info
  try {
    practiceInfo = await getPracticeInfo(practiceId, accessToken)
    localStorage.setItem(LOCAL_STORAGE_KEY_API_RESPONSE_PRACTICE_INFO, JSON.stringify(practiceInfo))
  } catch (e) {
    // track error
    trackEventInGa('error-getting-practice-info', {
      error: e.message
    })
    // sign out if there is any error getting info
    window.location.href = `/${SIGN_OUT_ROUTE}`
    return
  }

  // get time out  info
  try {
    timeoutConfiguration = await getTimeOutConfig(practiceId, accessToken)
    localStorage.setItem(LOCAL_STORAGE_KEY_API_RESPONSE_TIMEOUT_CONFIG, JSON.stringify(timeoutConfiguration))
  } catch (e) {
    // track error
    trackEventInGa('error-getting-timeout-config', {
      error: e.message
    })
    // use default values
    timeoutConfiguration = {
      max_time_msec: 900000, // 15 minutes
      timeout_enabled: true
    }
  }

  // if it's an admin user coming from ppadmin reset the is active local storage
  if (document.referrer.includes('ppadmin')) {
    localStorage.removeItem(LOCAL_STORAGE_IS_ACTIVE)
  }

  // check how long the user has been active
  // if user has timeout enabled and has been inactive for longer than max time, then log out
  const currentActiveValue = localStorage.getItem(LOCAL_STORAGE_IS_ACTIVE)
  if (currentActiveValue && timeoutConfiguration.timeout_enabled && Date.now() - parseInt(currentActiveValue) > timeoutConfiguration.max_time_msec) {
    window.location.href = `/${SIGN_OUT_ROUTE}`
  }

  // set up ppConfig and apply as dataset to id=app
  const ppConfig = {
    practiceId: practiceInfo.legacy_practice_id,
    practiceUuid: practiceId,
    apiDomain: process.env.API_DOMAIN,
    apiToken: accessToken,
    token: accessToken,
    refreshToken,
    userType: getCookie('user_type') || 'INTERNAL',
    doNotUseHeartbeat: true,
    setupOneSignal: true,
    pendoEnabled: process.env.PENDO_ENABLED,
    testAccount: practiceInfo.test_account,
    switchWithCookies: true,
    cookieDomain: process.env.COOKIE_DOMAIN
  }
  Object.keys(ppConfig).forEach((key) => {
    document.getElementById('app').dataset[key] = ppConfig[key]
  })

  // store in local storage
  localStorage.setItem('ppPortalConfig', JSON.stringify(ppConfig))
  localStorage.setItem('ppApiToken', accessToken)

  // handle getting refresh token after exp
  refreshAccessToken(accessToken)

  // track user active
  const setUserActive = () => localStorage.setItem(LOCAL_STORAGE_IS_ACTIVE, Date.now().toString())
  USER_EVENTS.forEach(name => {
    window.addEventListener(name, setUserActive)
  })

  return practiceInfo
}
