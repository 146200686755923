import { getCookie } from '@/get-cookie'
import { parseJwt } from '@/parse-jwt'
import { getRefreshToken } from '@/get-refresh-token'
import { COOKIE_NAME } from '@/constants'

/**
 * Check cookies verify user
 * Check if token is expired, and if so then refresh it
 * If user is internal redirect to PpAdmin
 * @returns {boolean}
 */
export const verifyAuth = async () => {
  // while logging in from tebra.com we still need cookies set to patientpop.com, using hash to pass values through
  if (window.location.hash.includes('access_token')) {
    // convert has string for auth data passed in to url
    const cookieParams = `;path=/;max-age=86400;domain=${process.env.COOKIE_DOMAIN}`

    const locationHashParts = window.location.hash.slice(1).split('&')

    const allowedCookies = ['access_token', 'refresh_token', 'practice_id', 'user_type']

    // for each hash part set the cookie
    locationHashParts.forEach((hashPart) => {
      const hashParts = hashPart.split('=')
      if (hashParts[1] && allowedCookies.includes(hashParts[0])) {
        document.cookie = `${hashParts[0]}=${hashParts[1]}${cookieParams}`
      }
    })
    document.cookie = `login_domain=tebra.com${cookieParams}`
  }

  const accessToken = getCookie(COOKIE_NAME.ACCESS_TOKEN)
  const refreshToken = getCookie(COOKIE_NAME.REFRESH_TOKEN)
  const practiceUuid = getCookie(COOKIE_NAME.PRACTICE_ID)
  const userType = getCookie(COOKIE_NAME.USER_TYPE)

  // if not all cookies are set then return false
  if (!(accessToken && refreshToken)) {
    return false
  }

  // if we have cookie, check if need to refresh the cookie
  const decodedJwt = parseJwt(accessToken)
  const isTokenExpired = (decodedJwt.exp * 1000) - Date.now() <= 0

  // if its expired then get refresh token
  if (isTokenExpired) {
    return await getRefreshToken()
      .finally(() => {
        // check if internal user, then redirect to ppadmin
        if (!practiceUuid && userType === 'INTERNAL') {
          window.location.href = process.env.PPADMIN_LINK
          return
        }
        return true
      })
  } else {
    // otherwise return true

    // check if internal user, then redirect to ppadmin
    if (!practiceUuid && userType === 'INTERNAL') {
      window.location.href = process.env.PPADMIN_LINK
      return true
    }
    return true
  }
}
