import { getCookie } from '@/get-cookie'
import { COOKIE_NAME } from '@/constants'

/**
 * Refresh auth token from auth service
 * @param callBkFn
 * @returns {Promise<string>}
 */
export const getRefreshToken = async (callBkFn = null) => {
  const refreshToken = getCookie(COOKIE_NAME.REFRESH_TOKEN)
  return fetch(`${process.env.AUTHENTICATION_SERVICE_DOMAIN}/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.AUTHENTICATION_SERVICE_API_KEY
    },
    body: JSON.stringify({ refresh_token: refreshToken })
  })
    .then((response) => response.json())
    .then((data) => {
      const newAccessToken = data.session_token

      // setup for next time this expires
      if (callBkFn) {
        callBkFn(newAccessToken)
      }

      // store new auth token in cookie
      document.cookie = `access_token=${newAccessToken};path=/;max-age=86400;domain=${process.env.COOKIE_DOMAIN}`

      // store in local storage - used in interactions across UI
      localStorage.setItem('ppApiToken', newAccessToken)
      return newAccessToken
    })
}
