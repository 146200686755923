import { loadAssets } from '@/load-assets'
import { getCookie } from '@/get-cookie'
import { COOKIE_NAME, LOCAL_STORAGE_KEY_LOGGED_OUT } from '@/constants'

/**
 * Sign out
 */
export const signOut = () => {
  // revoke token
  fetch(`${process.env.AUTHENTICATION_SERVICE_DOMAIN}/revoke-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.AUTHENTICATION_SERVICE_API_KEY
    },
    body: JSON.stringify({ token: getCookie(COOKIE_NAME.ACCESS_TOKEN) })
  })
    .then((response) => response.json())
    .finally(() => {
      const cookiesToRemove = Object.values(COOKIE_NAME)
      cookiesToRemove.push('lrvls')
      // clear cookies
      cookiesToRemove.forEach((cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.COOKIE_DOMAIN}`
      })

      // clear local storage
      window.localStorage.clear()

      // store setting in local storage
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGGED_OUT, Date.now())

      // login UI to show user login page
      loadAssets('LOGIN')
    })
}
