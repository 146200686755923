import { SIGN_OUT_ROUTE } from '@/constants'
import { trackEventInGa } from 'pp-fe-utils'

/**
 * Handle errors in network calls
 * If there is ever a case of a 401 of any patientpop.com API calls, we want to log current user out and redirect to log in
 * (use case: de-activating a logged-in user)
 */
export const handleNetworkError = () => {
  const origOpen = XMLHttpRequest.prototype.open
  XMLHttpRequest.prototype.open = function (method, url) {
    // only handle if it's a patientpop.com API
    if (url.includes('patientpop.com')) {
      this.addEventListener('error', function () {
        trackEventInGa('api-request-error', { url, method, status: this.status, eventListener: 'error' })
      })
      this.addEventListener('loadend', function () {
        // if it's a 401 or 0
        if ([401, 0].includes(this.status)) {
          trackEventInGa('api-request-error', { url, method, status: this.status, eventListener: 'loadend' })

          // if its unauth response then log user out
          if (this.status === 401 && !url.includes('authentication/v1/login/sso')) {
            window.location.href = `/${SIGN_OUT_ROUTE}`
          }
        }
      })
    }

    origOpen.apply(this, arguments)
  }
}
