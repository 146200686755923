/**
 * Set up listeners for events to open/close portal settings
 */
export const portalSettings = () => {
  /**
   * Set up portal settings HTML based on page
   * @param page
   * @returns {string}
   */
  const portalSettingsHtml = (page = '') => {
    return `${process.env.PORTAL_APP_SETTINGS}${page ? `?page=${page}` : ''}`
  }

  // get elements
  const portalSettingsElement = document.getElementById('portal-settings-container')
  const portalSettingsDialog = document.getElementById('portal-settings-dialog')

  /**
   * Open portal settings
   * @param event
   */
  const openSettingsUI = (event) => {
    const page = event && event.detail && event.detail.page ? event.detail.page : null

    // if its first click then load the iframe
    if (portalSettingsElement.src === '') {
      portalSettingsElement.src = portalSettingsHtml(page)
    } else if (page) {
      const iframeDocument = portalSettingsElement.contentDocument
      iframeDocument.dispatchEvent(new CustomEvent('portal-settings-navigate', { detail: { page } }))
    }

    // show dialog
    portalSettingsDialog.showModal()

    document.getElementsByTagName('HTML')[0].style.setProperty('overflow', 'hidden', 'important')
  }

  /**
   * Close portal Settings
   */
  const closeSettingsUi = () => {
    portalSettingsDialog.close()
    document.getElementsByTagName('HTML')[0].style.removeProperty('overflow')
  }

  // If the browser is Firefox, loading the iframe later will change the navigator history, so load it initially
  if (navigator.userAgent.indexOf('Firefox') !== -1 && portalSettingsElement.src === '') {
    portalSettingsElement.src = portalSettingsHtml()
  }

  // listen to event for when to open portal settings
  document.addEventListener('portal-settings-open', (event) => {
    openSettingsUI(event)
  }, false)
  document.addEventListener('practice-settings-open', (event) => {
    openSettingsUI(event)
  }, false)
  document.addEventListener('profile-settings-open', () => {
    openSettingsUI({ detail: { page: '/profile/my-account' } })
  }, false)

  // hide app on close
  document.addEventListener('portal-settings-close', () => {
    closeSettingsUi()
  }, false)
  document.addEventListener('practice-settings-close', () => {
    closeSettingsUi()
  }, false)

  // listen to profile settings changes - used for auto logout / my account changes from settings
  document.addEventListener('profile-settings-updated', () => {
    // will be fire event to UI repo that listen to this event
    document.dispatchEvent(new CustomEvent('profile-settings-data-changed'))
  }, false)
}
